<template>
  <div class="pl-5 br-5" data-app>

    <div v-if="!loadD">
      <ValidationObserver ref="formEdit">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Provinsi</label>
            <div class="col-lg-9 col-xl-7">
                <validationProvider name="Provinsi" rules="required" v-slot="{ valid, errors }">
                    <b-form-group
                        id="input-group-user-1" 
                        label-for="input-user-1"
                        :invalid-feedback="errors[0]"
                        class="mb-0"
                        :state="valid">
                          <b-form-select2
                          id="input-user-1"
                          v-model="form.provinsi"
                          required
                          variant="solid"
                          size="lg"
                          :options="opsProvinsi"
                          placeholder="Silahkan pilih Provinsi"
                          :state="valid"
                          ></b-form-select2>
                    </b-form-group>
                </validationProvider>
            </div>
        </div>

        <!--begin::Form Group-->
        <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-lg-9 col-xl-7">
                <h5 class="font-weight-bold mb-3">Komoditas:</h5>
            </div>
        </div>   
        <b-row v-for="(rinc, index) in form.usaha" :key="index">
          <b-col cols="10" class="pb-0 pt-0">
            <h5 class="mt-4 text-muted">Rincian {{index + 1}}</h5>
          </b-col>
          <b-col cols="2" class="text-right">
            <b-button v-show="(form.usaha.length > 1) ? true : false" @click="deleteKomoditas(index)" size="sm" variant="link" class="text-hover-primary">
              <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
            </b-button>
          </b-col>
          <b-col cols="12" sm="6">
            <validationProvider :name="`Rin ${index+1} Komoditas`" rules="required" v-slot="{ valid, errors }">
              <b-form-group 
              :id="`input-group-5-${index}-1`" 
              label="Komoditas:" 
              :label-for="`input-5-${index}-1`"
              :invalid-feedback="errors[0]"
              :state="valid">    
                <b-form-select2
                  :id="`input-5-${index}-1`"
                  v-model="rinc.komoditas"
                  variant="solid"
                  size="lg"
                  @change="onChangeKomoditas(index)"
                  :options="opsTernakKomoditas"
                  required
                  placeholder="Silahkan pilih Komoditas"
                  :state="valid"
                ></b-form-select2>
              </b-form-group>
            </validationProvider>
          </b-col>
          <b-col cols="12" sm="6">
            <validationProvider :name="`Rin ${index+1} Tipe`" rules="required|min_value:1|max_value:9999999999" v-slot="{ valid, errors }">
              <b-form-group 
              :id="`input-group-5-${index}-2`" 
              label="Tipe:" 
              :label-for="`input-5-${index}-2`"
              :invalid-feedback="errors[0]"
              :state="valid">          
                <b-form-select2
                  :id="`input-5-${index}-2`"
                  v-model="rinc.tipe"
                  variant="solid"
                  size="lg"
                  :options="getTipeByKomoditas(rinc.komoditas)"
                  required
                  placeholder="Silahkan pilih Tipe"
                  :state="valid"
                ></b-form-select2>
              </b-form-group>
            </validationProvider>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="text-right">
            <b-button @click="addKomoditas" variant="link" class="text-hover-primary">
              <i class="flaticon2-plus-1 p-0"></i>
              Add Komoditas
            </b-button>
          </b-col>
        </b-row>
        
        <div class="separator separator-solid my-5 mt-15"></div>

        <div class="mt-10">
          <button 
          @click="onSubmit"
          ref="kt_submit" 
          class="btn btn-primary font-weight-bold">
            <i class="la la-save"></i>
            Perbarui
          </button>

          &nbsp;

          <button
          @click="onReset"
          class="btn btn-clean font-weight-bold">
            <i class="la la-recycle"></i>
            Reset
          </button>
        </div>
        
      </b-form>
      </ValidationObserver>     
    </div>
    <div v-else>
      <BulletListLoader></BulletListLoader>
    </div>
  
  </div>
  <!--end: List Widget 4-->
</template>

<script>
import { BulletListLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MasterService from "@/core/services/api/master/master.service";
import WilayahService from "@/core/services/api/master/wilayah.service";
import UserService from "@/core/services/api/master/user.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "master-data-user-akses-provinsi",
  props: {
    idser: {
      required: true
    },
    aks: {
      required: true
    }
  },
  components: {
    BulletListLoader
  },  
  data() {
    return {
      loadD: true,
      dataOri: null,
      form: {
        idser: this.idser,
        provinsi: null,
        usaha: [{
          komoditas: null,
          tipe: null
        }],
      },
      opsProvinsi: [
        { text: 'Silahkan Pilih Provinsi', value: null }],
      opsTernakKomoditas: [{
        value: null,
        text: "Silahkan pilih Komoditas"
      }],
      opsTernakTipe: [{
        value: null,
        text: "Silahkan pilih Tipe"
      }],
      show: true
    }
  },
  computed: {
    opsGroupTipe () {
      return this.opsTernakTipe.reduce((acc, obj) => {
        const property = obj['komoditas'];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    },
    
  },
  methods: {
    addKomoditas(){
      this.form.usaha.push({
            komoditas: null,
            tipe: null
          })
    },
    deleteKomoditas(index){
      if (this.form.usaha.length > 1) {
        this.form.usaha.splice(index,1);
      }
      else {
        this.form.usaha = [
          {
            komoditas: null,
            tipe: null
          }
        ]
      }
    },
    onChangeKomoditas(index) {
      this.form.usaha[index].tipe = null
    },
    getTipeByKomoditas(id) {
      let data = [{ text: 'Silahkan pilih Tipe', value: null }]
      if(!id) return data
      return [...data, ...this.opsGroupTipe[id]]
    },
    
    getTernakKomoditas() {
      MasterService.getTernakKomoditas()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsTernakKomoditas = [{
                  value: null,
                  text: "Silahkan pilih Komoditas"
                }]
                return;
            }

            this.opsTernakKomoditas = res.data.data
            this.opsTernakKomoditas.push({
              value: null,
              text: "Silahkan pilih Komoditas"
            })
        })
        .catch((err) => {
          this.opsTernakKomoditas = [{
            value: null,
            text: "Tidak ada Komoditas"
          }]
          ErrorService.status(err)
        })
    },
    getTernakTipe() {
      MasterService.getTernakTipe({ child: 1 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsTernakTipe = [{
                  value: null,
                  text: "Silahkan pilih Tipe"
                }]
                return;
            }

            this.opsTernakTipe = res.data.data
            this.opsTernakTipe.push({
              value: null,
              text: "Silahkan pilih Tipe"
            })
        })
        .catch((err) => {
          this.opsTernakTipe = [{
            value: null,
            text: "Tidak ada Tipe"
          }]
          ErrorService.status(err)
        })
    },
    getProvinsi() {
      WilayahService.getProvinsi({ aks: 1 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsProvinsi = [{
                  value: null,
                  text: "Silahkan Pilih Provinsi"
                }]
                return;
            }

            this.opsProvinsi = res.data.data
            this.opsProvinsi.push({
              value: null,
              text: "Silahkan Pilih Provinsi"
            })
        })
        .catch((err) => {
          this.opsProvinsi = [{
            value: null,
            text: "Tidak ada Provinsi"
          }]
          ErrorService.status(err)
        })
    },
    getData () {
      this.loadD = true
      UserService.getUserAkses({idser: this.idser})
        .then((res) => {
            this.loadD = false
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.$emit('error-show', true)
                return;
            }
            this.$emit('error-show', false)
            this.dataOri = JSON.stringify({ ...res.data.data, idser: this.idser })
            this.form = { ...res.data.data, idser: this.idser }
        })
        .catch((err) => {
          this.$emit('error-show', true)
          this.loadD = false
          ErrorService.status(err)
        })
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formEdit.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if (this.form.usaha.length == 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Harap memilih Data Komoditas terlebih dahulu',
            })
            return;
        }
        
        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            UserService.updateUserAkses(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    if (!res.data.status) {
                      this.$emit('error-show', true)
                      return
                    } 
                    this.dataOri = JSON.stringify(this.form)
                    this.$emit('error-show', false)
                    this.dialog = false
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  this.$emit('error-show', true)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
      this.form= JSON.parse(this.dataOri)
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data User" }]);
    this.getTernakKomoditas()
    this.getTernakTipe()
    this.getProvinsi()
    this.getData()
  },
};
</script>