<template>
  <div class="pl-5 br-5" data-app>

    <div v-if="!aks" class="text-right mb-5">
        <b-button @click="dialog = true" variant="warning" size="sm">Ubah Akses</b-button>
    </div>

    <div v-if="!loadD">
      <div v-if="list.length > 0">
        <div v-for="(item, index) in list" :key="index" class="border rounded p-3 mb-5">
          <div class="d-flex align-items-center mx-3">
            <div class="symbol symbol-35 symbol-light mr-4">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg src="media/svg/icons/Home/Building.svg"/>
                  <!--end::Svg Icon-->
                </span> 
              </span>
            </div>         
            <span class="text-dark-75 font-size-h5 font-weight-bolder mr-auto">
                {{ item.text }}
            </span>
          </div>
          
          <!--begin::Separator-->
          <div class="separator separator-solid mt-3 mb-4"></div>
          <!--end::Separator-->

          <div v-for="(rinc, indexRin) in item.usaha" :key="indexRin" class="align-items-center mx-sm-7 mx-2">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-35 symbol-light mr-4">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-sm svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Layout/Layout-polygon.svg"/>
                    <!--end::Svg Icon-->
                  </span> 
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1">
                <div class="text-muted font-weight-bold">
                  {{ rinc.komoditas.uraian }} | {{ rinc.tipe.uraian }} {{ rinc.tipeusaha.uraian }}
                </div>
              </div>
              <!--end::Text-->
            </div>
            <!--begin::Separator-->
            <div class="separator separator-border-2 separator-light separator-dashed mb-2"></div>
            <!--end::Separator-->
          </div>
        </div>
      </div>
      <div v-else class="border rounded p-3 mb-5 text-center">
        <span class="text-dark-75 font-size-lg font-weight-bolder">Tidak ada data Akses, silahkan tambah data</span>
      </div>

    </div>
    <div v-else>
      <BulletListLoader></BulletListLoader>
    </div>
    
    <v-dialog v-model="dialog" persistent max-width="600px">
      <div class="card card-custom gutter-b mb-0">
        <div
          class="card-header"
        >
          <div class="card-title">
            <slot name="title"></slot>
            <h3 class="card-label">
              Akses
            </h3>
          </div>
          <div class="card-toolbar">
            <button
            @click="dialog = false"
            class="btn btn-clean font-weight-bold">
              <i class="la la-close p-0"></i>
            </button>
          </div>
        </div>
        <div
          class="card-body"
        >

          <!--begin::Form-->
          <ValidationObserver ref="formEdit">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">

              <b-row v-for="(rinc, index) in form.rincian" :key="index">
                  <b-col cols="10" class="pb-0">
                      <h6 class="text-muted">Perusahaan {{index + 1}}</h6>
                  </b-col>
                  <b-col cols="2" class="text-right pb-0">
                      <b-button v-show="(form.rincian.length > 1) ? true : false" @click="deleteRincian(index)" size="sm" variant="link" class="text-hover-primary">
                      <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
                      </b-button>
                  </b-col>            
                  <b-col cols="12" class="pt-0 pb-5">
                      <validationProvider :name="`Perusahaan ${index+1}`" rules="required" v-slot="{ errors, valid }">
                      <b-form-group 
                      :id="`input-group-${index}-3`" 
                      label="Perusahaan:" 
                      :label-for="`input-${index}-3`"
                      :invalid-feedback="errors[0]"
                      :state="valid">
                          <b-form-select2
                          :id="`input-${index}-3`"
                          v-model="rinc.perusahaan"
                          required
                          :options="opsPerusahaan"
                          :state="valid"
                          >
                          </b-form-select2>
                      </b-form-group>
                      </validationProvider>
                  </b-col>
              </b-row>

              <b-row class="mb-5">
                  <b-col cols="12" class="text-right">
                      <b-button @click="addRincian" variant="clear-primary" class="text-hover-primary">
                      <i class="flaticon2-plus-1 p-0"></i>
                      Add Perusahaan
                      </b-button>
                  </b-col>
              </b-row>
          </b-form>
          </ValidationObserver>
          
          <div class="mt-10">
            <button 
            @click="onSubmit" 
            ref="kt_submit"
            class="btn btn-primary font-weight-bold">
              <i class="la la-save"></i>
              Perbarui
            </button>

            &nbsp;

            <button
            @click="onReset"
            class="btn btn-clean font-weight-bold">
              <i class="la la-recycle"></i>
              Reset
            </button>
          </div>
          <!--end::Form-->
      
        </div>
      </div>
    </v-dialog>
  </div>
  <!--end: List Widget 4-->
</template>

<script>
import { BulletListLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PerusahaanService from "@/core/services/api/master/perusahaan.service";
import UserService from "@/core/services/api/master/user.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "master-data-user-perusahaan",
  props: {
    idser: {
      required: true
    },
    aks: {
      required: true
    }
  },
  components: {
    BulletListLoader
  },  
  data() {
    return {
      dialog: false,
      loadD: true,
      form: {
        idser: this.idser,
        rincian: [
            {
                perusahaan: null,
            }
        ]
      },
      list: [],
      opsPerusahaan: [
        { text: 'Silahkan Pilih Perusahaan', value: null },
      ],
      show: true
    }
  },
  computed: {
    cekPerusahaan () {
      const lookup = this.form.rincian.reduce((a, e) => {
        a[e.perusahaan] = ++a[e.perusahaan] || 0;
        return a;
      }, {});

      return this.opsPerusahaan.filter(e => lookup[e.value] && e.value !== null)
    },
    namePerusahaan () {
        if (this.cekPerusahaan.length > 0) {
            return this.cekPerusahaan.map(function(item) {return ` ${item['text']}`})
        }
        return ''
    }
    
  },
  methods: {
    addRincian(){
      this.form.rincian.push({
          perusahaan: null
      })
    },
    deleteRincian(index){
      if (this.form.rincian.length > 1) {
        this.form.rincian.splice(index,1);
      }
      else {
        this.form.rincian = [{
                              perusahaan: null
                            }]
      }
    },    
    getPerusahaan() {
      PerusahaanService.getPerusahaan({child: 2})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsPerusahaan = [{
                  value: null,
                  text: "Silahkan Pilih Perusahaan"
                }]
                return;
            }

            this.opsPerusahaan = res.data.data
            this.opsPerusahaan.push({
              value: null,
              text: "Silahkan Pilih Perusahaan"
            })
        })
        .catch((err) => {
          this.opsPerusahaan = [{
            value: null,
            text: "Tidak ada Perusahaan"
          }]
          ErrorService.status(err)
        })
    },
    getData () {
      UserService.getUserAkses({idser: this.idser})
        .then((res) => {
            this.loadD = false
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.$emit('error-show', true)
                return;
            }
            this.$emit('error-show', false)
            this.list = res.data.data
        })
        .catch((err) => {
          this.loadD = false
          this.$emit('error-show', true)
          ErrorService.status(err)
        })
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formEdit.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if (this.form.rincian.length == 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Harap memilih Data Perusahaan terlebih dahulu',
            })
            return;
        }

        if (this.cekPerusahaan.length > 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: `Double input perusahaan: ${this.namePerusahaan}`,
            })
            return;
        }

        if (this.aks) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Tidak dapat merubah data akses',
            })
            return;
        }
        
        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            UserService.updateUserAkses(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    if (!res.data.status) {
                      this.$emit('error-show', true)
                      return
                    } 
                    this.getData()
                    this.$emit('error-show', false)
                    this.dialog = false
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  this.$emit('error-show', true)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
        this.form.rincian = [{
                              perusahaan: null,
                          }]
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data User" }]);
    this.getPerusahaan()
    this.getData()
  },
};
</script>