<template>
  <div data-app>
    
    <div class="card card-custom gutter-b">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <router-link
            to="/master-data/user"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
          <li class="nav-item">
            <a
              class="nav-link"
              role="tab"
              :href="href" 
              @click="navigate"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <span class="svg-icon svg-icon-md svg-icon-success m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Navigation/Left-2.svg"/>
                <!--end::Svg Icon-->
              </span>
              Kembali
            </a>
          </li>
          </router-link>
          <li class="nav-item">
            <a
              id="tab-menu-user"
              class="nav-link cursor-pointer active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              role="tab"
            >
              Edit Data
            </a>
          </li>
          <li class="nav-item">
            <a
              id="tab-menu-password"
              class="nav-link cursor-pointer"
              v-on:click="setActiveTab"
              data-tab="1"
              data-toggle="tab"
              role="tab"
            >
              Edit Password
            </a>
          </li>
          
          <li class="nav-item" v-show="this.fd == 'perusahaan' || this.fd == 'all'">
            <a
              id="tab-menu-perusahaan"
              class="nav-link cursor-pointer"
              v-on:click="setActiveTab"
              data-tab="2"
              data-toggle="tab"
              role="tab"
            >
              Perusahaan
              <span v-if="errPerusahaan" class="svg-icon svg-icon-md svg-icon-danger m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          
          <li class="nav-item" v-show="this.fd == 'provinsi'">
            <a
              id="tab-menu-provinsi"
              class="nav-link cursor-pointer"
              v-on:click="setActiveTab"
              data-tab="3"
              data-toggle="tab"
              role="tab"
            >
              Provinsi
              <span v-if="errProvinsi" class="svg-icon svg-icon-md svg-icon-danger m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          
          <li class="nav-item" v-show="this.fd== 'kabupaten'">
            <a
              id="tab-menu-kabupaten"
              class="nav-link cursor-pointer"
              v-on:click="setActiveTab"
              data-tab="4"
              data-toggle="tab"
              role="tab"
            >
              Kabupaten
              <span v-if="errKabupaten" class="svg-icon svg-icon-md svg-icon-danger m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
        </ul>
      </div>

      <!--begin::Form-->
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div v-if="dataOri">
                <ValidationObserver ref="formAdd">
                <b-form @submit="onSubmit" @reset="onReset" v-if="show">

                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Username</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Username" rules="required|username|min:3|max:100" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-user-2" 
                                  label-for="input-user-2"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-input
                                  autocomplete="off"
                                  id="input-user-2"
                                  v-model="form.username"
                                  class="form-control form-control-solid"
                                  required
                                  size="lg"
                                  placeholder="Username"
                                  :state="valid"
                                  ></b-form-input>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Email</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Email" rules="required|email|max:255" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-user-3" 
                                  label-for="input-user-3"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-input
                                  autocomplete="off"
                                  id="input-user-3"
                                  v-model="form.email"
                                  type="email"
                                  class="form-control form-control-solid"
                                  required
                                  size="lg"
                                  placeholder="Email"
                                  :state="valid"
                                  ></b-form-input>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="separator separator-dashed my-5 mt-15"></div>
                  <!--begin::Form Group-->
                  <div class="row">
                      <label class="col-xl-3"></label>
                      <div class="col-lg-9 col-xl-7">
                          <h5 class="font-weight-bold mb-6">Biodata User:</h5>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Nama Lengkap</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Nama" rules="required|min:5|max:50" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-user-1" 
                                  label-for="input-user-1"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-input
                                  autocomplete="off"
                                  id="input-user-1"
                                  v-model="form.biodata.name"
                                  class="form-control form-control-solid"
                                  required
                                  size="lg"
                                  placeholder="Nama Lengkap"
                                  :state="valid"
                                  ></b-form-input>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">No.HP</label>
                      <div class="col-lg-9 col-xl-7">
                          <b-form-group
                              id="input-group-user-8" 
                              label-for="input-user-8"
                              class="mb-0">
                              <b-input-group>
                                <b-input-group-prepend>
                                  <b-form-select2
                                    id="input-user-1"
                                    v-model="form.biodata.phone_code"
                                    required
                                    variant="solid"
                                    size="lg"
                                    :options="opsPhoneCode"
                                    placeholder="Phone Code"
                                    state="no"
                                    ></b-form-select2>
                                </b-input-group-prepend>
                                <b-form-input
                                  autocomplete="off"
                                  id="input-user-8"
                                  v-model="form.biodata.phone"
                                  type="number"
                                  class="form-control form-control-solid"
                                  required
                                  size="lg"
                                  placeholder="No.HP"
                                  ></b-form-input>
                              </b-input-group>
                          </b-form-group>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="separator separator-dashed my-5 mt-15"></div>
                  <!--begin::Form Group-->
                  <div class="row">
                      <label class="col-xl-3"></label>
                      <div class="col-lg-9 col-xl-7">
                          <h5 class="font-weight-bold mb-6">Akses User:</h5>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Tipe User</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Tipe User" rules="required" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-user-4" 
                                  label-for="input-user-4"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                  <b-form-radio-group 
                                  id="input-user-4" 
                                  v-model="form.tipe" 
                                  name="user-tipe">
                                    <b-form-radio v-for="(item, index) in opsTipe" :key="index" :value="item.value">
                                      {{ item.text }}
                                    </b-form-radio>
                                  </b-form-radio-group>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Role</label>
                      <div class="col-lg-9 col-xl-7">
                          <validationProvider name="Role" rules="required" v-slot="{ valid, errors }">
                              <b-form-group
                                  id="input-group-user-5" 
                                  label-for="input-user-5"
                                  :invalid-feedback="errors[0]"
                                  class="mb-0"
                                  :state="valid">
                                    <b-form-select2
                                    id="input-user-5"
                                    v-model="form.role"
                                    required
                                    variant="solid"
                                    size="lg"
                                    :options="opsRole"
                                    placeholder="Silahkan pilih Role"
                                    :state="valid"
                                    ></b-form-select2>
                              </b-form-group>
                          </validationProvider>
                      </div>
                  </div>
                  
                  <!--begin::Form Group-->
                  <div class="separator separator-dashed my-5 mt-15"></div>
                  <!--begin::Form Group-->
                  <div class="row">
                      <label class="col-xl-3"></label>
                      <div class="col-lg-9 col-xl-7">
                          <h5 class="font-weight-bold mb-3">Komoditas:</h5>
                      </div>
                  </div>   
                  <b-row v-for="(rinc, index) in form.komoditas" :key="index">
                    <b-col cols="10" class="pb-0 pt-0">
                      <h5 class="mt-4 text-muted">Rincian {{index + 1}}</h5>
                    </b-col>
                    <b-col cols="2" class="text-right">
                      <b-button v-show="(form.komoditas.length > 1) ? true : false" @click="deleteKomoditas(index)" size="sm" variant="link" class="text-hover-primary">
                        <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
                      </b-button>
                    </b-col>
                    <b-col cols="12" sm="6">
                      <validationProvider :name="`Rin ${index+1} Komoditas`" rules="required" v-slot="{ valid, errors }">
                        <b-form-group 
                        :id="`input-group-5-${index}-1`" 
                        label="Komoditas:" 
                        :label-for="`input-5-${index}-1`"
                        :invalid-feedback="errors[0]"
                        :state="valid">    
                          <b-form-select2
                            :id="`input-5-${index}-1`"
                            v-model="rinc.komoditas"
                            variant="solid"
                            size="lg"
                            @change="onChangeKomoditas(index)"
                            :options="opsTernakKomoditas"
                            required
                            placeholder="Silahkan pilih Komoditas"
                            :state="valid"
                          ></b-form-select2>
                        </b-form-group>
                      </validationProvider>
                    </b-col>
                    <b-col cols="12" sm="6">
                      <validationProvider :name="`Rin ${index+1} Tipe`" rules="required" v-slot="{ valid, errors }">
                        <b-form-group 
                        :id="`input-group-5-${index}-2`" 
                        label="Tipe:" 
                        :label-for="`input-5-${index}-2`"
                        :invalid-feedback="errors[0]"
                        :state="valid">          
                          <b-form-select2
                            :id="`input-5-${index}-2`"
                            v-model="rinc.tipe"
                            variant="solid"
                            size="lg"
                            :options="getTipeByKomoditas(rinc.komoditas)"
                            required
                            placeholder="Silahkan pilih Tipe"
                            :state="valid"
                          ></b-form-select2>
                        </b-form-group>
                      </validationProvider>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" class="text-right">
                      <b-button @click="addKomoditas" variant="link" class="text-hover-primary">
                        <i class="flaticon2-plus-1 p-0"></i>
                        Add Komoditas
                      </b-button>
                    </b-col>
                  </b-row>
                  
                  <div class="separator separator-solid my-5 mt-15"></div>

                  <div class="mt-10">
                    <button 
                    @click="onSubmit" 
                    ref="kt_submit"
                    class="btn btn-primary font-weight-bold">
                      <i class="la la-save"></i>
                      Perbarui
                    </button>

                    &nbsp;

                    <button
                    @click="onReset"
                    class="btn btn-clean font-weight-bold">
                      <i class="la la-recycle"></i>
                      Reset
                    </button>
                  </div>
                  
                </b-form>
                </ValidationObserver>  
                
              </div>
              <div v-else>
                <BulletListLoader></BulletListLoader>
              </div>          
            </b-tab>

            <b-tab>
              <ValidationObserver ref="formPass">
              <div v-if="showPass">
                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Password</label>
                    <div class="col-lg-9 col-xl-7">
                        <validationProvider name="Password" rules="required|password|min:8|max:150" v-slot="{ valid, errors }">
                            <b-form-group
                                id="input-group-user-6" 
                                label-for="input-user-6"
                                :invalid-feedback="errors[0]"
                                class="mb-0"
                                :state="valid">
                                <b-form-input
                                autocomplete="off"
                                id="input-user-6"
                                v-model="formPass.password"
                                class="form-control form-control-solid"
                                required
                                type="password"
                                size="lg"
                                placeholder="Password"
                                :state="valid"
                                ></b-form-input>
                            </b-form-group>
                        </validationProvider>
                    </div>
                </div>

                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Confirmation</label>
                    <div class="col-lg-9 col-xl-7">
                        <validationProvider name="Password Confirmation" rules="required|min:8|max:150|matchPassword:@Password" v-slot="{ valid, errors }">
                            <b-form-group
                                id="input-group-user-7" 
                                label-for="input-user-7"
                                :invalid-feedback="errors[0]"
                                class="mb-0"
                                :state="valid">
                                <b-form-input
                                autocomplete="off"
                                id="input-user-7"
                                v-model="formPass.password_confirmation"
                                class="form-control form-control-solid"
                                required
                                type="password"
                                size="lg"
                                placeholder="Password Confirmation"
                                :state="valid"
                                ></b-form-input>
                            </b-form-group>
                        </validationProvider>
                    </div>
                </div>

                <div class="separator separator-solid my-5 mt-15"></div>

                <div class="mt-10">
                  <button 
                  @click="onNotifPassShow" 
                  ref="kt_submit_pass"
                  class="btn btn-primary font-weight-bold">
                    <i class="la la-save"></i>
                    Perbarui
                  </button>

                  &nbsp;

                  <button
                  @click="onResetPass"
                  class="btn btn-clean font-weight-bold">
                    <i class="la la-recycle"></i>
                    Reset
                  </button>
                </div>

              </div>
              </ValidationObserver>
            </b-tab>
            
            <b-tab>
              <div v-if="this.fd == 'perusahaan' || this.fd == 'all'">
                <AksesPerusahaan :idser="idUS" :aks="aks" @error-show="onErrPerusahaan"></AksesPerusahaan>
              </div>
            </b-tab>
            <b-tab>
              <div v-if="this.fd == 'provinsi'">
                <AksesProvinsi :idser="idUS" :aks="aks" @error-show="onErrProvinsi"></AksesProvinsi>
              </div>
            </b-tab>
            <b-tab>
              <div v-if="this.fd =='kabupaten'">
                <AksesKabupaten :idser="idUS" :aks="aks" @error-show="onErrKabupaten"></AksesKabupaten>
              </div>
            </b-tab>
          </b-tabs>
        </div>

    </div>

    <v-dialog
    v-model="dialogSubmitPass"
    max-width="300">
      <v-card>
        <v-card-title class="headline">Nonaktif User</v-card-title>

        <v-card-text>
          Anda yakin akan merubah password user, password lama tidak dapat dikembalikan!!
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <b-button
          variant="link-success"
          class="m-3"
          @click="onNotifPassBatal">
            BATAL
          </b-button>

          <b-button
          variant="link-danger"
          class="m-3"
          @click="onSubmitPass">
            UBAH PASS
          </b-button>
        </v-card-actions>
      </v-card>
    </v-dialog> 

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BulletListLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MasterService from "@/core/services/api/master/master.service";
import RoleService from "@/core/services/api/master/role.service";
import UserService from "@/core/services/api/master/user.service";
import ErrorService from "@/core/services/error.service";

import AksesPerusahaan from "@/view/pages/master-data/user/akses/Perusahaan.vue";
import AksesProvinsi from "@/view/pages/master-data/user/akses/Provinsi.vue";
import AksesKabupaten from "@/view/pages/master-data/user/akses/Kabupaten.vue";

import { extend } from 'vee-validate';

extend('matchPassword', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});

extend('password', {
  validate(value) {
    const validPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{1,}$/
    return value.match(validPassword);
  },
  message: 'Password must use a combination of uppercase (A-Z), lowercase (a-z) and numbers (0-9).'
});

extend('username', {
  validate(value) {
    const validUsername = /^(?!.*__.*)(?!.*\.\..*)[a-z0-9_.]+$/
    return value.match(validUsername);
  },
  message: 'Username only letters (a-z), numbers (0-9), underscores (_) and periods (.) Are allowed.'
});

export default {
  name: "master-data-user-edit",
  props: {
    idUS: {
      required: true
    }
  },
  data() {
    return {
      tabIndex: 0,
      errPerusahaan: false,
      errProvinsi: false,
      errKabupaten: false,
      dataOri: null,
      form: {
        idser: this.idUS,
        username: null,
        email: null,
        tipe: null,
        role: null,
        status: null,
        biodata: {
          name: null,
          phone_code: null,
          phone: null,
          avatar: null,
        },
        komoditas: [{
          komoditas: null,
          tipe: null
        }],
      },
      formPass: {
        idser: this.idUS,
        password: null,
        password_confirmation: null
      },
      opsTipe: [],
      opsRole: [
        { text: 'Silahkan pilih Role', value: null, akses: false },
      ],
      opsPhoneCode: [
        { text: 'Phone Code', value: null },
      ],
      opsTernakKomoditas: [{
        value: null,
        text: "Silahkan pilih Komoditas"
      }],
      opsTernakTipe: [{
        value: null,
        text: "Silahkan pilih Tipe"
      }],
      dialogSubmitPass: false,
      show: true,
      showPass: true,
      redirectTab: true
    }
  },  
  components: {
    BulletListLoader,
    AksesPerusahaan,
    AksesProvinsi,
    AksesKabupaten
  },
  computed: {
    ...mapGetters(["currentUser"]),
    fd () {
      if (!this.dataOri || this.opsTipe.length < 1) return
      const tipe = JSON.parse(this.dataOri).tipe
      return this.opsTipe.find((v)=>{ return v.value == tipe}).fd
    },
    aks () {
      if (!this.dataOri || this.opsRole.length < 2) return
      const rl = JSON.parse(this.dataOri).role
      return parseInt(this.opsRole.find((v)=>{ return v.value == rl}).aks)
    },
    opsGroupTipe () {
      return this.opsTernakTipe.reduce((acc, obj) => {
        const property = obj['komoditas'];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    },
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    /**
     * Set current active on route
     * @param id
     */
    setActiveTabRoute(id) {
      const data = JSON.parse(this.dataOri) 
      if (!data.tipe) return
      if (id != 'user' && id != 'password' && id != 'perusahaan' && id != 'provinsi' && id != 'kabupaten') return

      const event = document.getElementById(`tab-menu-${id}`)
      const tab = event.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.getAttribute("data-tab"));

      // set current active tab
      event.classList.add("active");

      this.redirectTab = false
    },
    onErrPerusahaan(val) {
      this.errPerusahaan = val
    },
    onErrProvinsi(val) {
      this.errProvinsi = val
    },
    onErrKabupaten(val) {
      this.errKabupaten = val
    },

    addKomoditas(){
      this.form.komoditas.push({
            komoditas: null,
            tipe: null
          })
    },
    deleteKomoditas(index){
      if (this.form.komoditas.length > 1) {
        this.form.komoditas.splice(index,1);
      }
      else {
        this.form.komoditas = [
          {
            komoditas: null,
            tipe: null
          }
        ]
      }
    },
    onChangeKomoditas(index) {
      this.form.komoditas[index].tipe = null
    },
    getTipeByKomoditas(id) {
      let data = [{ text: 'Silahkan pilih Tipe', value: null }]
      if(!id) return data
      return [...data, ...this.opsGroupTipe[id]]
    },
    getTernakKomoditas() {
      MasterService.getTernakKomoditas()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsTernakKomoditas = [{
                  value: null,
                  text: "Silahkan pilih Komoditas"
                }]
                return;
            }

            this.opsTernakKomoditas = res.data.data
            this.opsTernakKomoditas.push({
              value: null,
              text: "Silahkan pilih Komoditas"
            })
        })
        .catch((err) => {
          this.opsTernakKomoditas = [{
            value: null,
            text: "Tidak ada Komoditas"
          }]
          ErrorService.status(err)
        })
    },
    getTernakTipe() {
      MasterService.getTernakTipe({ child: 1 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsTernakTipe = [{
                  value: null,
                  text: "Silahkan pilih Tipe"
                }]
                return;
            }

            this.opsTernakTipe = res.data.data
            this.opsTernakTipe.push({
              value: null,
              text: "Silahkan pilih Tipe"
            })
        })
        .catch((err) => {
          this.opsTernakTipe = [{
            value: null,
            text: "Tidak ada Tipe"
          }]
          ErrorService.status(err)
        })
    },
    getTipeUser() {
      MasterService.getTipeUser()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsTipe = []
                return;
            }

            this.opsTipe = res.data.data
        })
        .catch((err) => {
          this.opsTipe = []
          ErrorService.status(err)
        })
    },
    getRole() {
      RoleService.getRole()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsRole = [{
                  value: null,
                  text: "Silahkan pilih Role"
                }]
                return;
            }

            this.opsRole = res.data.data
            this.opsRole.push({
              value: null,
              text: "Silahkan pilih Role"
            })
        })
        .catch((err) => {
          this.opsRole = [{
            value: null,
            text: "Tidak ada Role"
          }]
          ErrorService.status(err)
        })
    },
    getPhoneCode() {
      MasterService.getPhoneCode()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsPhoneCode = [{
                  value: null,
                  text: "Phone Code"
                }]
                return;
            }

            this.opsPhoneCode = res.data.data
            this.opsPhoneCode.push({
              value: null,
              text: "Phone Code"
            })
        })
        .catch((err) => {
          this.opsPhoneCode = [{
            value: null,
            text: "Tidak ada Phone Code"
          }]
          ErrorService.status(err)
        })
    },
    getData () {
      UserService.getUserSingle({idser: this.idUS})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.dataOri = JSON.stringify(res.data.data)
            this.form = res.data.data

            this.$nextTick(() => {
              if (this.$route.query.tab && this.redirectTab) {
                this.setActiveTabRoute(this.$route.query.tab)
              }
            })
        })
        .catch((err) => ErrorService.status(err))
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            UserService.updateUser(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.dataOri = JSON.stringify(this.form)
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
      this.form = JSON.parse(this.dataOri)

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },

    onNotifPassShow() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_submit_pass"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$refs.formPass.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })

            // dummy delay
            setTimeout(() => {
                submitButton.classList.remove(
                    "spinner",
                    "spinner-light",
                    "spinner-right"
                );
            }, 1000);
            return;
        }

        this.dialogSubmitPass = true
      })
    },
    onNotifPassBatal() {
       // set spinner to submit button
        const submitButton = this.$refs["kt_submit_pass"];

        // dummy delay
        setTimeout(() => {
            this.dialogSubmitPass = false

            this.removeSpinner(submitButton)
        }, 1000);
    },
    onSubmitPass(evt) {
      evt.preventDefault()
      this.$refs.formPass.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit_pass"];
        this.dialogSubmitPass = false

        // dummy delay
        // setTimeout(() => {
            UserService.updateUserPass(this.formPass)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.$nextTick(() => {
                        this.onResetPass()
                      })
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })
        // }, 1000);
      })
    },
    onResetPass() {
      // Reset our form values
      
      this.formPass.password = null
      this.formPass.password_confirmation = null  
      // Trick to reset/clear native browser form validation state
      this.showPass = false
      this.$nextTick(() => {
        this.showPass = true
      })
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data User" }]);
    if (!this.currentUser.menu.user) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getTipeUser()
    this.getPhoneCode()
    await this.getRole()
    await this.getTernakTipe()
    await this.getTernakKomoditas()
    await this.getData()
  },
};
</script>
